import { useCallback, useEffect, useState } from 'react';
import getCmp from '@src/utils/cmp';

/**
 * Hook checks if consent is enabled for a concrete third-party service.
 *   Using:
 *    const { hasConsent } = useHasConsent('some service')
 *
 * @param consentName
 *   Consent service name, e.g.:
 *   - 'ablida'
 *   - 'AdAffairs'
 *   - 'Addictive Mobility'
 *   - 'AddThis'
 *   - 'Adjust GmbH'
 *   - 'Advertising Alliance'
 *   - 'Advolution'
 *   - 'AGF Videoforschung (Nielsen)'
 *   - 'Algolia'
 *   - 'Amazon Custom Audiences'
 *   - 'Apple'
 *   - 'AppNexus (adnxs.com)'
 *   - 'AppsFlyer'
 *   - 'Arrivalist'
 *   - 'Audience Science'
 *   - 'Aumago'
 *   - 'AWIN'
 *   - 'Batch'
 *   - 'bet-at-home'
 *   - 'Bluesummit'
 *   - 'Branch'
 *   - 'Braze'
 *   - 'Burda Direct interactive GmbH'
 *   - 'Centro'
 *   - 'Cloudflare'
 *   - 'Conversion Linker'
 *   - 'Conviva'
 *   - 'coop'
 *   - 'Cross Domain and Device PSDD'
 *   - 'Cross Domain and Device PULS4'
 *   - 'Cybertime GmbH'
 *   - 'Dalet'
 *   - 'Dell'
 *   - 'Deutsche Telekom'
 *   - 'Digital Element Inc.'
 *   - 'Display & Video 360'
 *   - 'Dr. Oetker'
 *   - 'Dynatrace'
 *   - 'Ensighten"
 *   - 'esome'
 *   - 'Event Collector'
 *   - 'Experian'
 *   - 'Facebook Connect'
 *   - 'Facebook Pixel'
 *   - 'Facebook Social Plugins'
 *   - 'Fielmann'
 *   - 'Freewheel'
 *   - 'Glomex'
 *   - 'Google Ad Manager'
 *   - 'Google Ads Conversion Tracking'
 *   - 'Google Ads Custom Audiences'
 *   - 'Google Ads Remarketing'
 *   - 'Google Ads'
 *   - 'Google Analytics 4'
 *   - 'Google Analytics'
 *   - 'Google Campaign Manager 360'
 *   - 'Google Display & Video 360'
 *   - 'Google Enhanced Conversions'
 *   - 'Google Firebase'
 *   - 'Google Maps'
 *   - 'Henkel'
 *   - 'Hotjar'
 *   - 'hyScore.io GmbH'
 *   - 'INFOnline'
 *   - 'ING'
 *   - 'Instabug'
 *   - 'Instagram Content'
 *   - 'Intel'
 *   - 'interceptd'
 *   - 'Interrogare GmbH'
 *   - 'LemonPi'
 *   - 'Magna Global MediaPlus'
 *   - 'Meta Custom Audiences'
 *   - 'MetrixLab'
 *   - 'Microsoft Advertising'
 *   - 'Microsoft Clarity'
 *   - 'Microsoft Custom Audienc's"
 *   - 'Middleware'
 *   - 'Nestle'
 *   - 'Netpoint Media GmbH'
 *   - 'New Relic'
 *   - 'NicePeopleAtWork'
 *   - 'Nielsen DAR'
 *   - 'Nielsen DCR Video Browser SDK'
 *   - 'Nintendo'
 *   - 'Omnicom'
 *   - 'Optimizely'
 *   - 'Outbrain"
 *   - 'Philips'
 *   - 'pilot Hamburg GmbH'
 *   - 'Podigee'
 *   - 'Project Agora'
 *   - 'ProSiebenSat.1 Digital Data GmbH (Analyse mit 7Pass Daten)'
 *   - 'ProSiebenSat.1 Digital Data GmbH (Werbetargeting mit 7Pass Daten)'
 *   - 'PubStream Advertising'
 *   - 'PulsePoint'
 *   - 'Qubit Opentag'
 *   - 'Radeberger'
 *   - 'Ratiopharm'
 *   - 'Scoota'
 *   - 'Search Ads 360'
 *   - 'SENSIC.net'
 *   - 'Sevenonemedia Ad Block Prevention'
 *   - 'sky deutschland'
 *   - 'Sky Firebolt Metrics'
 *   - 'Smart RTB LLC'
 *   - 'Tealium AudienceStream'
 *   - 'Tealium Inc'
 *   - 'TikTok Advertising'
 *   - 'TikTok Custom Audiences'
 *   - 'TikTok'
 *   - 'Tradedoubler AB'
 *   - 'Tremor Video, Inc.'
 *   - 'TV-Insight GmbH'
 *   - 'Twitter Plugin'
 *   - 'Usersnap'
 *   - 'Viant'
 *   - 'Virtual Minds'
 *   - 'Vodafone GmbH'
 *   - 'Vorwerk & Co. KG'
 *   - 'Wall-E'
 *   - 'Webtrekk'
 *   - 'Webtrends Inc.'
 *   - 'Xaxis'
 *   - 'Yieldr'
 *   - 'YouGov'
 *   - 'YouTube Video'
 *   - 'Zulu5'
 *
 */

const useHasConsent = (consentName: string) => {
  const [hasConsent, setHasConsent] = useState<boolean>(false);

  const updateConsent = useCallback(async () => {
    const cmp = await getCmp()
    if (!cmp) {
      // Consent banner disabled.
      setHasConsent(false)
      return
    }
    const status = cmp
      .getConsents()
      .filter(c => c.name === consentName)
      .shift()?.consent?.status ?? false;

     setHasConsent(status)
  }, [consentName]);

  useEffect(() => {
    document.addEventListener('cmp:saved', updateConsent);
    document.addEventListener('cmp:confirmed', updateConsent);
    document.addEventListener('cmp:userActionNotRequired', updateConsent);
    document.addEventListener('cmp:failed', updateConsent);

    return () => {
      document.removeEventListener('cmp:saved', updateConsent);
      document.removeEventListener('cmp:confirmed', updateConsent);
      document.removeEventListener('cmp:userActionNotRequired', updateConsent);
      document.removeEventListener('cmp:failed', updateConsent);
    };
  }, [updateConsent]);

  useEffect(() => {
    updateConsent()
  }, [updateConsent]);

  return { hasConsent };
};

export default useHasConsent;
